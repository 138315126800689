import React from 'react';
import { Routes, Route } from 'react-router';
import Links from '../pages/links/links';

function RoutingApp() {
    return (
        <Routes>
            <Route path="/" element={<Links />} />
<Route path="/Links" element={<Links />} />

        </Routes>
    );

}

export default RoutingApp;