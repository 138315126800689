import React from 'react';import { Link } from 'react-router-dom';


import styles from './links.module.css';

/**
 * Links component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Links component.
 */
const Links = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Links}>
                
<section custom-agua-item="layer" className={styles.linksbody}   >
<section custom-agua-item="layer" className={styles['layer-6-a4f28b01b69e4107accdf631ae29fcd0']}   ></section>
<p custom-agua-item="text" className={styles['text-7-921b0f780ae348f7ae8ae22a498ad933']}   >@catatumbo_editorial</p>
<p custom-agua-item="text" className={styles['text-8-c3216cb26b80498ca94a968e4481408d']}   >Somos una editorial independiente que busca darle un espacio a quienes deseen dar a conocer su voz</p>
<section custom-agua-item="layer" className={styles.logos}   >
<section custom-agua-item="layer" className={styles['layer-14-1c149304b62647a9a8cb378086b4de89']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CRLPJ1VK")}></section>
<section custom-agua-item="layer" className={styles['layer-15-94187e425684445893676ff98861362d']}   onClick={() => openInNewTab("https://www.instagram.com/catatumbo_editorial/")}></section>
<section custom-agua-item="layer" className={styles['layer-16-7730914131234125bf25b35f05357347']}   onClick={() => openInNewTab("https://www.linkedin.com/company/catatumbo-edutorial/?viewAsMember=true")}></section>
<section custom-agua-item="layer" className={styles['layer-17-54309929867f412b85485f06cbb25086']}   onClick={() => openInNewTab("https://open.spotify.com/show/2YUXzlSkOAxCGObKafIuq8")}></section>
</section>
<section custom-agua-item="layer" className={styles['layer-5-abb2f2c31fe943518777e8de233248fd']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CRLPJ1VK")}>
<p custom-agua-item="text" className={styles['text-1-3650edda7d8a4d528ef143059adb284d']}   >UN AMOR EN 4 PARTES - AMAZON</p>
</section>
<section custom-agua-item="layer" className={styles['layer-9-9e2c803162434b918bde7f60cf6878bf']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CRYBMDZH")}>
<p custom-agua-item="text" className={styles['text-3-eb3233d281194332bc792e3cf45ae98a']}   >RUMORES DE ROCAS Y MARIPOSAS - AMAZON</p>
</section>
<section custom-agua-item="layer" className={styles['layer-92-b22b9448105f42ec821fa07b128c7e2a']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CXYJ5W21")}>
<p custom-agua-item="text" className={styles['text-32-e3a8174074d54a7eb62259e1fc80b3f9']}   >CUERPOS GORDOS MÁS ALLÁ DE LAS AULAS: HISTORIAS NO CONTADAS - AMAZON</p>
</section>
<section custom-agua-item="layer" className={styles['layer-10-b0d25733c20d47ce94eea909d5985893']}   onClick={() => openInNewTab("https://open.spotify.com/show/2YUXzlSkOAxCGObKafIuq8")}>
<p custom-agua-item="text" className={styles['text-4-27aa27341d9a49aab89627fa89409931']}   >NUESTRO PODCAST</p>
</section>
<section custom-agua-item="layer" className={styles['layer-11-b3b7a138961746059f4c77ebb0f1bd41']}   onClick={() => openInNewTab("https://www.linkedin.com/company/catatumbo-edutorial/?viewAsMember=true")}>
<p custom-agua-item="text" className={styles['text-5-57f60127957f4057bfae6c80e551e1a4']}   >LINKEDIN</p>
</section>
<section custom-agua-item="layer" className={styles['layer-12-b381e04a71bc4b489aab7d13b9e9273a']}   onClick={() => openInNewTab("https://www.letrascatatumbo.com/Home")}>
<p custom-agua-item="text" className={styles['text-6-4d504e27d61e401c95de18757de92286']}   >LETRAS CATATUMBO</p>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Links;
            